.skills-container {
    display: grid;
    grid-template-columns: 1fr;
}

.skills-container-with-view-skills {
    grid-template-columns: 1fr 1fr !important;
}

.update-search-skill {
    position: relative;
    font-size: 10px;
    top: -6px;
    right: -5px;
    color: #fff;
}

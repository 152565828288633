.upload-req-radio input[type=radio] {
    display: none;
}

.upload-req-radio label {
    display: inline-block;
    cursor: pointer;
    position: relative;
    padding-left: 30px !important;
    padding-right: 10px;
    padding-bottom: 5px;
}
.upload-req-radio label:before {
    background: none repeat scroll 0 0 #ffffff;
    border: 2px solid #e7ebee ;
    border-radius: 50% ;
    background-clip: padding-box ;
    content: "" ;
    display: inline-block;
    height: 20px ;
    left: 0 ;
    margin-right: 10px;
    position: absolute;
    width: 20px ;
    border-color: #03a9f4;
}
.upload-req-radio input[type=radio]:checked+label:after {
    transform: scale(0.63);
    -webkit-transform: scale(0.63);
    -moz-transform: scale(0.63);
}
.upload-req-radio label:after {
    background-color: #03a9f4;
    border: 1px solid #03a9f4;
    border-radius: 50%;
    background-clip: padding-box;
    content: "";
    display: inline-block;
    font-size: 13px;
    height: 16px;
    left: 2px;
    position: absolute;
    text-align: center;
    top: 2px;
    transform: scale(0);
    width: 16px;
    z-index:1;
}

.requisition-description-heading {
    color: #03a9f4;
    line-height: 29px;
}